import { cn } from '@/components/ui/primitive'
import { Icon } from '@/components/icons'

interface VerifyBadgeProps {
    check?: boolean
    className?: string
}

export function VerifyBadge({ check, className }: VerifyBadgeProps) {
    return (
        <div className={cn('absolute -right-1 -top-1', className)}>
            {check && <Icon iconName="PatchCheckFill" className="size-4 fill-primary" />}
        </div>
    )
}
