import { usePage } from '@inertiajs/react'
import { PageData } from '@/types/generated'

export function __(key: string, replace = {}) {
    const { language } = usePage<PageData>().props
    // @ts-ignore
    let translation = language[key] ? language[key] : key

    Object.keys(replace).forEach(function (key) {
        // @ts-ignore
        translation = translation.replace(':' + key, replace[key])
    })

    return translation
}

export function flashMessage(params: any) {
    return params.props.flashMessage
}

export function limitChars(string: string, limit: number = 20) {
    return string.length > limit ? string.slice(0, limit) + '...' : string
}

export function kebabCase(string: string) {
    return string
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
}

export function numberFormat(number: number, decimals: number = 0, dec_point?: string, thousands_sep: string = '.') {
    // number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
        dec = typeof dec_point === 'undefined' ? '.' : dec_point,
        s: any[],
        toFixedFix = function (n: number, prec: number) {
            let k = Math.pow(10, prec)
            return '' + Math.round(n * k) / k
        }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
}

export function formatDate(input: string | number): string {
    const date = new Date(input)
    return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })
}

export function pickBy<T extends Record<string, any>>(
    object: T,
    predicate: (value: any, key: string) => boolean = (value, key) => value !== undefined && value !== '',
): Partial<T> {
    return Object.keys(object).reduce<Partial<T>>((acc, key) => {
        if (predicate(object[key], key)) {
            ;(acc as Record<string, any>)[key] = object[key]
        }
        return acc
    }, {})
}
