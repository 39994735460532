import { Link } from '@inertiajs/react'
import Logo from '@/components/logo'
import { __ } from '@/lib/utils'
import ApplicationLogo from '@/components/application-logo'

const navigation = {
    general: [
        { name: 'Beranda', href: '/' },
        { name: 'Artikel', href: '/articles' },
        { name: 'Etalase', href: '/products' },
    ],
    auth: [
        { name: 'Masuk', href: '/login' },
        { name: 'Daftar', href: '/register' },
    ],
    legal: [
        { name: 'Privasi', href: '/privacy' },
        { name: 'Ketentuan', href: '/terms' },
    ],
}

export const filters = [
    {
        name: 'Populer Minggu ini',
        href: '/articles/week',
    },
    {
        name: 'Populer Bulan ini',
        href: '/articles/month',
    },
    {
        name: 'Populer Tahun ini',
        href: '/articles/year',
    },
    {
        name: 'Populer sepanjang waktu',
        href: '/articles/all-time',
    },
    {
        name: 'Terbaru',
        href: '/articles',
    },
]
export function Footer() {
    return (
        <footer className="border-t border-muted" aria-labelledby="footer-heading">
            <div className="relative top-0 -z-10 h-full w-full bg-bg">
                <div className="bg-theme absolute -bottom-64 -left-20 right-auto top-auto h-40 w-40 -translate-x-[30%] translate-y-[20%] rounded-full opacity-10 blur-[140px] md:h-[700px] md:w-[700px]"></div>
            </div>
            <h2 id="footer-heading" className="sr-only">
                Catatan Kaki
            </h2>
            <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8 lg:py-32">
                <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                    <div className="z-10 flex flex-row items-start space-x-1.5 md:space-x-2.5 lg:space-x-4">
                        <ApplicationLogo className="size-6 dark:contrast-100 dark:invert md:size-8 lg:size-12" />
                        <Logo className="text-2xl md:text-4xl lg:text-6xl" />
                    </div>
                    <div className="z-10 mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-foreground text-sm font-semibold leading-6">Umum</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {navigation.general.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                href={item.href}
                                                className="text-muted-foreground hover:text-foreground text-sm leading-6"
                                            >
                                                {__(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-foreground text-sm font-semibold leading-6">Akun</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {navigation.auth.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                href={item.href}
                                                className="text-muted-foreground hover:text-foreground text-sm leading-6"
                                            >
                                                {__(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-2 md:gap-8">
                            <div>
                                <h3 className="text-foreground text-sm font-semibold leading-6">Populer</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {filters.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                href={item.href}
                                                className="text-muted-foreground hover:text-foreground text-sm leading-6"
                                            >
                                                {__(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="mt-10 md:mt-0">
                                <h3 className="text-foreground text-sm font-semibold leading-6">Kebijakan</h3>
                                <ul role="list" className="mt-6 space-y-4">
                                    {navigation.legal.map((item) => (
                                        <li key={item.name}>
                                            <Link
                                                href={item.href}
                                                className="text-muted-foreground hover:text-foreground text-sm leading-6"
                                            >
                                                {__(item.name)}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
