import { SVGAttributes } from 'react'

export default function ApplicationLogo(props: SVGAttributes<SVGElement>) {
    return (
        <svg width="404" height="404" viewBox="0 0 404 404" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M206.594 0.181978C205.757 0.334165 203.17 0.86682 200.887 1.32338C191.68 3.14963 183.157 8.17179 176.157 15.8572C171.591 20.8794 166.645 30.1628 165.275 36.2503C164.514 39.5224 164.058 59.4589 164.286 80.9173L164.362 91.0377H237.336C277.513 91.0377 310.842 91.266 311.527 91.4943C312.592 91.9508 312.745 99.1036 312.745 164.849C312.745 204.95 312.973 238.279 313.201 238.964C313.582 240.029 316.397 240.181 336.79 240.181C363.651 240.181 368.141 239.572 377.805 234.702C388.458 229.3 395.306 221.995 400.176 210.961C404.285 201.83 404.133 205.178 403.905 99.0275L403.676 0.105867L305.896 0.0297718C252.098 -0.0463218 207.431 0.029791 206.594 0.181978Z"
                fill="#09090b"
            />
            <path
                d="M62.7772 77.8736C58.3638 78.1779 52.048 79.6237 46.8737 81.2978C42.1559 82.8957 32.2637 87.9179 29.5243 90.1246C24.9587 93.701 17.7298 101.082 14.3817 105.496C10.4248 110.822 4.56562 121.78 4.56562 123.91C4.56562 124.595 4.26124 125.432 3.80468 125.66C3.42421 125.889 3.04374 127.106 3.04374 128.247C3.04374 129.389 2.73937 130.606 2.3589 131.063C1.97843 131.443 1.44578 133.802 1.1414 136.313C0.83703 138.825 0.456562 199.852 0.304374 271.988L0 403.021H132.783C223.183 403.021 266.784 402.793 269.219 402.184C274.394 401.043 278.655 399.902 280.633 399.141C281.546 398.76 283.068 398.151 284.057 397.771C287.406 396.477 295.167 392.444 296.004 391.607C296.232 391.379 297.602 390.314 299.048 389.325C302.396 386.966 309.397 380.041 312.364 376.008C313.582 374.334 314.875 372.812 315.104 372.584C316.321 371.595 323.474 355.691 324.463 351.582C326.746 342.755 327.279 332.178 327.05 301.056L326.822 287.234L320.202 287.006L313.506 286.778L313.277 306.154C313.049 339.636 312.669 345.875 310.842 350.897C309.32 355.235 304.603 365.051 303.69 365.736C303.461 365.964 302.32 367.334 301.178 368.855C295.928 375.856 290.449 380.269 281.546 384.531C269.143 390.466 278.655 390.086 139.556 390.086C35.2313 390.086 15.2948 389.933 14.5339 389.02C13.9251 388.259 13.6968 355.463 13.849 262.172L14.0773 136.313L16.2079 130.226C18.3386 124.215 22.9042 115.159 24.8826 113.257C25.4153 112.648 26.7849 111.05 27.9264 109.605C30.894 105.724 38.5034 99.9407 44.8952 96.7448C56.8419 90.7334 56.3093 90.8094 96.6389 90.5051C116.119 90.3529 132.479 90.0485 133.012 89.8963C133.696 89.6681 133.925 87.9179 133.773 83.6566L133.544 77.7214L99.6826 77.6453C81.0397 77.6453 64.4513 77.7214 62.7772 77.8736Z"
                fill="#09090b"
            />
            <path
                d="M90.1706 120.79C79.2131 121.475 71.5277 123.91 64.1466 129.085C54.2544 136.085 47.8626 145.293 44.8949 156.935C43.4491 162.642 43.373 165.762 43.373 260.65C43.373 314.449 43.6774 358.963 43.9818 359.496C44.5145 360.257 63.9944 360.409 144.273 360.181C237.488 359.953 244.184 359.876 248.369 358.583C258.642 355.539 267.697 349.452 273.784 341.69C276.98 337.505 281.394 328.145 282.687 322.591C284.057 316.96 284.361 263.77 282.992 263.77C282.535 263.77 281.546 264.151 280.709 264.531C279.948 264.988 276.6 266.053 273.404 266.966C267.545 268.64 266.403 268.64 202.18 268.869C153.861 269.097 136.512 268.945 135.751 268.26C134.914 267.575 134.685 257.302 134.685 205.787C134.685 167.512 134.99 142.02 135.522 138.368C135.903 135.172 137.196 129.998 138.262 126.954C139.327 123.91 140.088 121.247 139.86 121.095C139.327 120.562 98.6931 120.334 90.1706 120.79Z"
                fill="#a1a1aa"
            />
            <path
                d="M182.625 122.16C176.385 123.454 169.689 128.856 167.178 134.487C164.514 140.803 164.362 143.847 164.362 191.481V238.659H214.356C251.641 238.659 265.034 238.431 267.012 237.746C274.622 235.159 280.861 228.006 282.307 220.397C282.764 217.734 282.992 198.558 282.916 169.034L282.688 121.856L233.988 121.78C207.203 121.703 184.07 121.856 182.625 122.16Z"
                fill="#a1a1aa"
            />
        </svg>
    )
}
