import { Button } from '@/components/ui/button'
import { Icon } from '@/components/icons'
import { router, usePage } from '@inertiajs/react'
import { __, flashMessage } from '@/lib/utils'
import { toast } from 'sonner'
import { Label } from '@/components/ui/field'
import { PageData } from '@/types/generated'
import { buttonStyles, Tooltip } from 'ui'

export function LanguageSwitcher() {
    const { locale } = usePage<PageData>().props
    return (
        <Tooltip>
            <Tooltip.Trigger
                className={buttonStyles({
                    appearance: 'plain',
                    size: 'square-petite',
                    shape: 'circle',
                })}
                aria-label={`Switch language to ${locale}`}
                onPress={() =>
                    router.post(
                        route('language'),
                        {},
                        {
                            preserveScroll: true,
                            onSuccess: (params) => {
                                const flash = flashMessage(params)
                                if (flash) {
                                    // @ts-ignore
                                    toast[flash.type](flash.message)
                                }
                            },
                        },
                    )
                }
            >
                <Icon iconName="Translate" size="xl" appearance="label" />
            </Tooltip.Trigger>
            <Tooltip.Content>{__('Sesuaikan bahasa sesuai selera kamu')}</Tooltip.Content>
        </Tooltip>
    )
}
