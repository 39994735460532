import { Icon } from '@/components/icons'
import { useTheme } from '@/components/theme-provider'
import { __ } from '@/lib/utils'
import { Button, buttonStyles, Tooltip } from 'ui'

export function ThemeSwitcher() {
    const { theme, setTheme } = useTheme()

    return (
        <Tooltip>
            <Tooltip.Trigger
                aria-label={'Switch to ' + theme === 'light' ? 'dark' : 'light' + 'mode'}
                className={buttonStyles({
                    appearance: 'plain',
                    size: 'square-petite',
                    shape: 'circle',
                })}
                onPress={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            >
                <Icon
                    iconName="Sun"
                    className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
                    size="xl"
                />
                <Icon
                    iconName="Moon"
                    className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
                    size="xl"
                />
            </Tooltip.Trigger>
            <Tooltip.Content>{__('Pilih tema yang bikin kamu nyaman')}</Tooltip.Content>
        </Tooltip>
    )
}
