import {
    ArrowLeftShort,
    ArrowRight,
    ArrowRightShort,
    Bullseye,
    Check,
    CheckCircle,
    CheckCircleFill,
    Circle,
    CircleFill,
    Cpu,
    Dash,
    Dot,
    IconProps,
    Journals,
    Lightbulb,
} from 'react-bootstrap-icons'

import {
    Bag,
    BarChart,
    Box,
    BoxArrowLeft,
    BoxArrowRight,
    BoxSeam,
    CashCoin,
    Chat,
    ChatSquare,
    ChevronDown,
    ChevronUp,
    Collection,
    Copy,
    CreditCard,
    Envelope,
    Facebook,
    Fire,
    Forward,
    Gear,
    Github,
    Google,
    Heart,
    House,
    JournalBookmark,
    JournalText,
    Laptop,
    List,
    Moon,
    PatchCheckFill,
    Pencil,
    Phone,
    Pin,
    Plus,
    Search,
    Speedometer,
    Star,
    StarFill,
    StarHalf,
    Stars,
    Sun,
    Tag,
    Telegram,
    ThreeDotsVertical,
    Toggles2,
    Translate,
    Trash,
    Triangle,
    Twitter,
    Upload,
    Whatsapp,
} from 'react-bootstrap-icons'

export const icons = {
    JournalText,
    Speedometer,
    Gear,
    BoxArrowRight,
    House,
    BoxArrowLeft,
    Forward,
    Translate,
    Stars,
    Fire,
    Tag,
    Search,
    Chat,
    Bag,
    Phone,
    CreditCard,
    CashCoin,
    StarHalf,
    StarFill,
    Star,
    List,
    JournalBookmark,
    Collection,
    Box,
    Toggles2,
    Sun,
    Moon,
    Laptop,
    PatchCheckFill,
    ChevronUp,
    ChevronDown,
    BarChart,
    Heart,
    Upload,
    Envelope,
    Copy,
    Facebook,
    Twitter,
    Whatsapp,
    Telegram,
    ChatSquare,
    ThreeDotsVertical,
    Triangle,
    Google,
    Github,
    BoxSeam,
    Trash,
    Plus,
    Pencil,
    Pin,
    ArrowRight,
    Bullseye,
    Lightbulb,
    Cpu,
    Journals,
    Check,
    Circle,
    CircleFill,
    ArrowRightShort,
    ArrowLeftShort,
    CheckCircle,
    CheckCircleFill,
}

// import * as icons from 'react-bootstrap-icons'
import { cn } from '@/components/ui/primitive'
import { tv } from 'tailwind-variants'

interface Props extends IconProps {
    iconName: keyof typeof icons
    appearance?: 'icon' | 'label' | 'text' | 'breadcrumbs' | 'longText' | 'toast'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
    intent?:
        | 'danger'
        | 'primary'
        | 'info'
        | 'warning'
        | 'secondary'
        | 'success'
        | 'light/dark'
        | 'theme'
        | 'black'
        | 'white'
    className?: string
}

const iconStyles = tv(
    {
        base: 'inline-block align-middle',
        variants: {
            appearance: {
                icon: ['mr-0'],
                label: ['mr-0.5'],
                text: ['mr-1.5'],
                longText: ['mr-2.5'],
                toast: ['pr-1.5'],
                breadcrumbs: ['mx-1'],
            },
            size: {
                xs: ['size-2'],
                sm: ['size-2.5'],
                md: ['size-3'],
                lg: ['size-3.5'],
                xl: ['size-4'],
                '2xl': ['size-5'],
                '3xl': ['size-6'],
                '4xl': ['size-8'],
            },
            intent: {
                danger: ['fill-rose-600 hover:fill-rose-500'],
                info: ['fill-rose-600 hover:fill-rose-500'],
                primary: ['fill-primary-600 hover:fill-primary-500'],
                warning: ['fill-amber-500 hover:fill-amber-400'],
                secondary: ['fill-muted hover:fill-mute/80'],
                success: ['fill-green-600 hover:fill-green-500'],
                theme: ['fill-fg'],
                white: ['fill-white'],
                black: ['fill-black'],
                'light/dark': ['dark:fill-light fill-dark'],
            },
        },
        defaultVariants: {
            appearance: 'icon',
            size: 'md',
            intent: 'theme',
        },
    },
    {
        responsiveVariants: true,
    },
)
export const Icon = ({ iconName, className, intent, appearance, size, ...props }: Props) => {
    const BootstrapIcon = icons[iconName]
    return <BootstrapIcon {...props} className={cn(iconStyles({ size, intent, appearance }), className)} />
}
