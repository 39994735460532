import { Menu } from '@/components/ui/menu'
import ApplicationLogo from '@/components/application-logo'
import Logo from '@/components/logo'
import { Icon } from './icons'
import { __ } from '@/lib/utils'
import { cn } from './ui/primitive'

export function LogoName({ className }: { className?: string }) {
    return (
        <div className={cn('z-50', className)}>
            <Menu>
                <Menu.Trigger>
                    <div className="flex items-center gap-x-2">
                        <ApplicationLogo className="size-4 dark:contrast-100 dark:invert" />
                        <Logo className="pr-8" />
                    </div>
                </Menu.Trigger>
                <Menu.Content className="min-w-48 bg-bg/50 backdrop-blur-md duration-0" placement="bottom">
                    <Menu.Item href="/" className="flex items-center">
                        <Icon iconName="House" size="lg" appearance="label" intent="theme" />
                        {__('Beranda')}
                    </Menu.Item>
                    <Menu.Separator />
                    <Menu.Item isDisabled={true}>
                        <span className="text-xs font-normal text-muted-fg">{__('Artikel')}</span>
                    </Menu.Item>
                    <Menu.Item href="/articles" className="flex items-center">
                        <Icon iconName="Stars" size="lg" appearance="label" intent="theme" />
                        {__('Terbaru')}
                    </Menu.Item>
                    <Menu.Item href="/articles/all-time" className="flex items-center">
                        <Icon iconName="Fire" size="lg" appearance="label" intent="theme" />
                        {__('Populer')}
                    </Menu.Item>
                    <Menu.Separator />
                    <Menu.Item isDisabled={true}>
                        <span className="text-xs font-normal text-muted-fg">{__('Etalase')}</span>
                    </Menu.Item>
                    <Menu.Item href="/products" className="flex items-center">
                        <Icon iconName="Tag" size="lg" appearance="label" intent="theme" />
                        {__('Diskon')}
                    </Menu.Item>
                </Menu.Content>
            </Menu>
        </div>
    )
}
