import { CommandPalette } from '@/components/command-palette'
import { Icon } from '@/components/icons'
import { ThemeSwitcher } from '@/components/theme-switcher'
import { cn } from '@/components/ui/primitive'
import { VerifyBadge } from '@/components/verify-badge'
import { router, usePage } from '@inertiajs/react'
import { LayoutGroup } from 'framer-motion'
import { useEffect, useState } from 'react'
import { Avatar, Button, buttonStyles, Label, Link, Menu, Separator, Tooltip } from 'ui'
// import { ResponsiveNavbar } from '@/layouts/partials/responsive-navbar';
// import { BottomMenu } from '@/layouts/partials/bottom-menu';
import { LanguageSwitcher } from '@/components/language-switcher'
import { ResponsiveNavbar } from '@/components/responsive-navbar'
import { __ } from '@/lib/utils'
import { PageData } from '@/types/generated'
import { Container } from './container'
import { LogoName } from './logo-menu'

export function Navbar() {
    const { auth, cartCountGlobal } = usePage<PageData>().props
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const body = document.body
        let lastScroll = 0

        window.addEventListener('scroll', () => {
            const currentScroll = window.scrollY
            if (currentScroll <= 0) {
                body.classList.remove('scroll-down')
                return
            }

            if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
                // body.classList.remove('scroll-up')
                body.classList.add('scroll-down')
            }

            // if (currentScroll > lastScroll && !body.classList.contains('scroll-down')) {
            //     body.classList.remove('scroll-up')
            //     body.classList.add('scroll-down')
            // } else if (currentScroll < lastScroll && body.classList.contains('scroll-down')) {
            //     body.classList.remove('scroll-down')
            //     body.classList.add('scroll-up')
            // }

            lastScroll = currentScroll
        })
    }, [])

    function logout() {
        router.post('/logout')
    }

    return (
        <div className="sticky top-0 z-40 mx-auto w-full px-0">
            <CommandPalette setOpen={setOpen} open={open} />
            <nav className="navbar-transition hidden border-b border-muted/50 bg-bg/50 py-3 backdrop-blur backdrop-filter md:block">
                <Container>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                            <LogoName />
                            <LayoutGroup id="Navlink">
                                <NavLink href="/" className="font-medium">
                                    {__('Beranda')}
                                </NavLink>
                                <NavLink href="/articles" className="font-medium">
                                    {__('Artikel')}
                                </NavLink>
                                <NavLink href="/series" className="font-medium">
                                    {__('Seri')}
                                </NavLink>
                                <NavLink href="/products" className="font-medium">
                                    {__('Etalase')}
                                </NavLink>
                            </LayoutGroup>
                        </div>
                        <div className="flex items-center gap-x-4">
                            <div className="flex items-center gap-x-1.5">
                                <Tooltip>
                                    <Tooltip.Trigger
                                        aria-label="Search button"
                                        className={buttonStyles({
                                            size: 'small',
                                            appearance: 'plain',
                                            shape: 'circle',
                                        })}
                                        onPress={() => setOpen((open: boolean) => !open)}
                                    >
                                        <Icon iconName="Search" appearance="icon" size="xl" />
                                        {/* <MenuKeyboard className="-mr-2 [&_kbd]:min-w-[3ch]" keys="⌘K" /> */}
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>{__('Temukan semuanya di sini')}</Tooltip.Content>
                                </Tooltip>
                                {auth.user && (
                                    <>
                                        <Link
                                            href="/chats"
                                            aria-label="chat"
                                            className={buttonStyles({
                                                shape: 'circle',
                                                appearance: 'plain',
                                                size: 'square-petite',
                                            })}
                                        >
                                            <Icon iconName="Chat" size="xl" />
                                        </Link>
                                        <Link
                                            href="/carts"
                                            aria-label="chat"
                                            className={cn(
                                                buttonStyles({
                                                    shape: 'circle',
                                                    appearance: 'plain',
                                                    size: 'square-petite',
                                                }),
                                                'relative',
                                            )}
                                        >
                                            <Icon iconName="Bag" className="mr-0.5" size="xl" />
                                            <Label className="absolute right-0 top-0 flex size-3.5 items-center justify-center rounded-full bg-primary text-center font-mono text-xs text-zinc-50">
                                                {cartCountGlobal}
                                            </Label>
                                        </Link>
                                    </>
                                )}
                                <Separator orientation="vertical" className="mx-2 h-4" />
                                <LanguageSwitcher />
                                <ThemeSwitcher />
                                <Separator orientation="vertical" className="mx-2 h-4" />
                                {auth.user ? (
                                    <Menu>
                                        <Menu.Trigger
                                            aria-label="auth"
                                            className="text-muted-foreground hover:text-foreground relative transition duration-200 focus:outline-none"
                                        >
                                            <VerifyBadge className="z-10" check={auth.user.is_verify} />
                                            <Avatar
                                                size="medium"
                                                src={auth.user.gravatar}
                                                initials={auth.user.initials}
                                            />
                                        </Menu.Trigger>
                                        <Menu.Content>
                                            <Menu.Section>
                                                <Menu.Header separator>
                                                    <span className="block">{auth.user.name}</span>
                                                    <span className="block text-xs font-normal text-muted-fg">
                                                        {auth.user.email}
                                                    </span>
                                                </Menu.Header>
                                            </Menu.Section>
                                            <Menu.Item href="/dashboard">
                                                <Icon
                                                    iconName="Speedometer"
                                                    size="lg"
                                                    appearance="label"
                                                    intent="theme"
                                                />
                                                {__('Dasbor')}
                                            </Menu.Item>
                                            <Menu.Item href={route('profile.edit')}>
                                                <Icon iconName="Gear" size="lg" appearance="label" intent="theme" />
                                                {__('Pengaturan')}
                                            </Menu.Item>
                                            <Menu.Item onAction={logout}>
                                                <Icon
                                                    iconName="BoxArrowRight"
                                                    size="lg"
                                                    appearance="label"
                                                    intent="theme"
                                                />
                                                {__('Keluar')}
                                            </Menu.Item>
                                        </Menu.Content>
                                    </Menu>
                                ) : (
                                    <NavLink
                                        href="/login"
                                        aria-label="login"
                                        className={cn(
                                            buttonStyles({
                                                shape: 'circle',
                                                appearance: 'plain',
                                                size: 'medium',
                                            }),
                                            'flex h-full',
                                        )}
                                    >
                                        Masuk
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </nav>
            <ResponsiveNavbar />
            {/*<BottomMenu open={open} setOpen={setOpen} />*/}
        </div>
    )
}

export function NavLink({ className = '', href = '', ...props }) {
    const { url } = usePage<PageData>()
    const hrefMod = href.substring(1, 2) == '' ? '/index' : href
    const urlMod = url.substring(1, 2) == '' ? '/index' : url
    const active = new RegExp(hrefMod).test(urlMod)
    return (
        <Link
            href={href}
            className={cn(
                'relative flex items-center rounded-full px-2 text-sm text-muted-fg outline-none transition forced-color-adjust-none group-orientation-vertical:w-full group-orientation-vertical:py-0 hover:text-fg',
                active && 'font-medium text-fg',
            )}
            {...props}
        >
            {__(props.children)}
        </Link>
    )
}
